import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Link as Link1 } from 'react-scroll';
import * as Unicons from "@iconscout/react-unicons";

export default function Navbar(){
    const [menuOpen, setMenuOpen] = useState(false);

    function toggleMenu() {
        setMenuOpen(!menuOpen);
        // Prevent body scrolling when menu is open
        if (!menuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }

    window.addEventListener("scroll", windowScroll);

    function windowScroll() {
        const navbar = document.getElementById("navbar");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            if (navbar !== null) {
                navbar?.classList.add("is-sticky");
            }
        } else {
            if (navbar !== null) {
                navbar?.classList.remove("is-sticky");
            }
        }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }

    function closeMenu() {
        setMenuOpen(false);
        // Restore body scrolling
        document.body.style.overflow = 'auto';
    }

    return(
        <nav className="navbar" id="navbar">
            <div className="container flex flex-wrap items-center justify-between">
                <Link1 to="top" spy={true} smooth={true} duration={500} className="navbar-brand md:me-8" href="#">
                    <img src="/images/logo-dark.png" className="inline-block dark:hidden" alt=""/>
                    <img src="/images/logo-light.png" className="hidden dark:inline-block" alt=""/>
                </Link1>

                <div className="nav-icons flex items-center lg_992:order-2 ms-auto md:ms-8">
                    {/* Navbar Button */}
                    <ul className="list-none menu-social mb-0">
                        <li className="inline-flex">
                            <Link to="https://www.linkedin.com/in/alaemoumouh/" className="ms-1">
                                <span className="login-btn-primary"><span className="btn btn-icon btn-sm rounded-full bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white"><Unicons.UilLinkedin width={16}/></span></span>
                                <span className="login-btn-light"><span className="btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"><Unicons.UilLinkedin width={16}/></span></span>
                            </Link>
                            <Link to="https://www.instagram.com/alae.amazigh/" className="ms-1">
                                <span className="login-btn-primary "><span className="btn btn-icon btn-sm rounded-full bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white"><Unicons.UilInstagram width={16}/></span></span>
                                <span className="login-btn-light "><span className="btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"><Unicons.UilInstagram width={16}/></span></span>
                            </Link>
                            <Link to="https://twitter.com/Alae72132060" className="ms-1">
                                <span className="login-btn-primary"><span className="btn btn-icon btn-sm rounded-full bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white"><Unicons.UilTwitter width={16}/></span></span>
                                <span className="login-btn-light"><span className="btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"><Unicons.UilTwitter width={16}/></span></span>
                            </Link>
                        </li>
                    </ul>
                    {/* Navbar Collapse Menu Button */}
                    <button onClick={toggleMenu} type="button" className="collapse-btn inline-flex items-center ms-2 text-dark dark:text-white lg_992:hidden" aria-controls="menu-collapse" aria-expanded={menuOpen}>
                        <span className="sr-only">Navigation Menu</span>
                        <i className="mdi mdi-menu text-[24px]"></i>
                    </button>
                </div>

                {/* Navbar Menu */}
                <div className={`navigation lg_992:order-1 lg_992:flex ${menuOpen ? 'block' : 'hidden'} ms-auto`} id="menu-collapse">
                    <ul className="navbar-nav" id="navbar-navlist">
                        <li className="nav-item">
                            <Link1 onClick={closeMenu} to="home" activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">Home</Link1>
                        </li>
                        <li className="nav-item">
                            <Link1 onClick={closeMenu} to="about" activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">About Me</Link1>
                        </li>
                        <li className="nav-item">
                            <Link1 onClick={closeMenu} to="service" activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">Services</Link1>
                        </li>
                        <li className="nav-item">
                            <Link1 onClick={closeMenu} to="experience" activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">Experience</Link1>
                        </li>
                        <li className="nav-item">
                            <Link1 onClick={closeMenu} to="project" activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">Projects</Link1>
                        </li>
                        <li className="nav-item">
                            <Link1 onClick={closeMenu} to="blog" activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">News</Link1>
                        </li>
                        <li className="nav-item">
                            <Link1 onClick={closeMenu} to="contact" activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">Contact Me</Link1>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
