import React from "react";
export default function Experience(){
    return(
        <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="experience">
            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">Experience & Study</h3>

                    <p className="text-slate-400 max-w-xl mx-auto text-[15px]">Below, you'll discover an overview of both my work experience and my academic journey. From hands-on projects in web design and development to dedicated study in application development and AI, I've cultivated a well-rounded skill set ready to tackle any challenge.</p>
                </div>

                <div className="grid grid-cols-1 mt-8">
                    <div className="relative after:content-[''] after:absolute after:top-0 ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 after:w-px after:h-full md:after:m-auto after:border-s-2 after:border-dashed after:border-gray-200 dark:after:border-gray-700 ms-3 md:ms-0">
                        
                        <div className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 ltr:after:-left-9 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="md:text-end md:me-8 relative">
                                    <img src="/images/logos/facebook-logo-2019.png" className="rounded-full h-9 w-9 md:ms-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">Troom</h5>
                                    <h6 className="text-sm mb-0">2024-present</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                                    <h5 className="title mb-1 font-semibold">Private Driver</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]">Providing exclusive transportation for high-ranking individuals and CEOs, ensuring comfort and reliability tailored to their needs and schedules.</p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 ltr:after:-left-9 rtl:md:after:left-0 rtl:md:after:right-0 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="text-start md:ms-8 relative md:order-2">
                                    <img src="/images/logos/google-logo.png" className="rounded-full h-9 w-9 md:me-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">KV Mechelen</h5>
                                    <h6 className="text-sm mb-0">2024-present</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                    <h5 className="title mb-1 font-semibold">Shuttle Driver</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]">Providing essential transportation services for players, staff, and visitors. With a focus on safety and efficiency, they ensure timely arrivals and departures to support the smooth functioning of the club's operations.</p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 ltr:after:-left-9 rtl:md:after:left-0 rtl:md:after:right-0 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="md:text-end md:me-8 relative">
                                    <img src="/images/logos/lenovo-logo.png" className="rounded-full h-9 w-9 md:ms-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">Thomas More University</h5>
                                    <h6 className="text-sm mb-0">2022-present</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                                    <h5 className="title mb-1 font-semibold">Application Development & AI</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]">Students merge AI and app development. They learn AI algorithms and machine learning alongside mobile and web app creation. Graduates innovate AI-driven applications, tackling real-world issues across sectors.</p>
                                </div>
                            </div>
                        </div>



                        <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 ltr:after:-left-9 rtl:md:after:left-0 rtl:md:after:right-0 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="text-start md:ms-8 relative md:order-2">
                                    <img src="/images/logos/circle-logo.png" className="rounded-full h-9 w-9 md:me-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">Karel de Grote University</h5>
                                    <h6 className="text-sm mb-0">2020-22</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                    <h5 className="title mb-1 font-semibold">Applied Computer Science</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]">Involving the application of computer science principles, encompasses software development, cybersecurity, data analysis, and artificial intelligence. Students acquire hands-on experience and foundational knowledge, preparing them for careers in various technology-related fields.</p>
                                </div>
                            </div>
                        </div>



                        <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 ltr:after:-left-9 rtl:md:after:left-0 rtl:md:after:right-0 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="md:text-end md:me-8 relative">
                                    <img src="/images/logos/fc-heikant.png" className="rounded-full h-9 w-9 md:ms-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">FC Heikant</h5>
                                    <h6 className="text-sm mb-0">2020-22</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                                    <h5 className="title mb-1 font-semibold">Football Trainer</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]">As a trainer for the under 11 football team, I focused on developing young players' skills, teamwork, and passion for the game. Beyond coaching techniques, I emphasized discipline, sportsmanship, and personal growth, laying the groundwork for their football journey.</p>
                                </div>
                            </div>
                        </div>


                        


                        <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 ltr:after:-left-9 rtl:md:after:left-0 rtl:md:after:right-0 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="text-start md:ms-8 relative md:order-2">
                                    <img src="/images/logos/kingslize-pizza.png" className="rounded-full h-9 w-9 md:me-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">Kingslize Premium Pizza</h5>
                                    <h6 className="text-sm mb-0">2020-22</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                    <h5 className="title mb-1 font-semibold">Delivery Driver</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]">As a delivery driver for Kingslize Pizza, my role centered around ensuring prompt and accurate deliveries to customers. While it didn't involve computer science principles like software development or cybersecurity, it provided valuable hands-on experience in customer service.</p>
                                </div>
                            </div>
                        </div>


                
                       
                    </div>
                </div>
            </div>
        </section>
    )
}