import React, { useState } from "react";
import { Link as Link2 } from "react-router-dom";
import * as Unicons from '@iconscout/react-unicons';
import Lightbox from 'react-18-image-lightbox';
import '../../node_modules/react-18-image-lightbox/style.css';

const images = {
    "React": [
        "/images/works/React Description.PNG",
        "/images/works/React 1.PNG",
        "/images/works/React 2.PNG",
        "/images/works/React 3.PNG",
        "/images/works/React 4.PNG",
        "/images/works/React 5.PNG",
    ],
    "Requirements Analysis": [
        "/images/works/RA Description.PNG",
        "/images/works/RA 1.PNG",
        "/images/works/RA 2.PNG",
        "/images/works/RA 3.PNG",
        "/images/works/RA 4.PNG",
        "/images/works/RA 5.PNG",
    ],
    "Qlik Sense": [
        "/images/works/Qlik Description.PNG",
        "/images/works/Qlik 1.PNG",
        "/images/works/Qlik 2.PNG",
        "/images/works/Qlik 3.PNG",
        "/images/works/Qlik 4.PNG",
        "/images/works/Qlik 5.PNG",
        "/images/works/Qlik 6.PNG",
    ],
    "Project PHP": [
        "/images/works/PHP Description.PNG",
        "/images/works/PHP 1.PNG",
    ],
    "Machine Learning": [
        "/images/works/ML Description.PNG",
        "/images/works/ML 1.PNG",
        "/images/works/ML 2.PNG",
        "/images/works/ML 3.PNG",
        "/images/works/ML 4.PNG",
        "/images/works/ML 5.PNG",
        "/images/works/ML 6.PNG",
    ],
    "Internet Of Things": [
        "/images/works/IOT Description.PNG",
        "/images/works/IOT 1.PNG",
    ],
    "Augmented Reality": [
        "/images/works/Flutter Description.PNG",
        "/images/works/Flutter 1.PNG",
        "/images/works/Flutter 2.PNG",
        "/images/works/Flutter 3.PNG",
        "/images/works/Flutter 4.PNG",
        "/images/works/Flutter 5.PNG",
        "/images/works/Flutter 6.PNG",
        "/images/works/Flutter 7.PNG",
        "/images/works/Flutter 8.PNG",
        "/images/works/Flutter 9.PNG",
        "/images/works/Flutter 10.PNG",
        "/images/works/Flutter 11.PNG",
    ],
    "Deep Learning": [
        "/images/works/DL Description.PNG",
        "/images/works/DL 1.PNG",
        "/images/works/DL 2.PNG",
        "/images/works/DL 3.PNG",
        "/images/works/DL 4.PNG",
        "/images/works/DL 5.PNG",
        "/images/works/DL 6.PNG",
        "/images/works/DL 7.PNG",
    ],
    "DevOps & Security": [
        "/images/works/DevOps Description.PNG",
        "/images/works/DevOps 1.PNG",
        "/images/works/DevOps 2.PNG",
        "/images/works/DevOps 3.PNG",
        "/images/works/DevOps 4.PNG",
        "/images/works/DevOps 5.PNG",
        "/images/works/DevOps 6.PNG",
        "/images/works/DevOps 7.PNG",
        "/images/works/DevOps 8.PNG",
        "/images/works/DevOps 9.PNG",
        "/images/works/DevOps 10.PNG",
    ],
};

const categoryTitles = {
    "React": "Football App",
    "Requirements Analysis": "UX Prototypes",
    "Qlik Sense": "Safety on the road",
    "Project PHP": "Kvv Rauw",
    "Machine Learning": "Heartfailure Challenge",
    "Internet Of Things": "Mouse Trap",
    "Augmented Reality": "Wikitude x Flutter",
    "Deep Learning": "Cats Challenge",
    "DevOps & Security": "Selenium Webscraper",
    // Add other category titles here
};

export default function Projects() {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [currentImages, setCurrentImages] = useState([]);

    const handleClick = (category, index) => {
        setCurrentImages(images[category]);
        setPhotoIndex(index);
        setIsOpen(true);
    };

    return (
        <>
            <section className="relative md:py-24 py-16" id="project">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">My Work & Projects</h3>
                        <p className="text-slate-400 max-w-xl mx-auto text-[15px]">Here, you'll find an array of projects I've carefully constructed and successfully completed over the course of the last four years, along with a sneak peek at what's in the pipeline.</p>
                    </div>

                    <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
                        {Object.keys(images).map((category, index) => (
                            <div key={index} className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                                <img src={images[category][0]} alt={`Project ${index + 1}`} />
                                <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                                <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                                    <Link2 to="#!" className="text-white hover:text-amber-500 font-semibold transition-all duration-500">{categoryTitles[category]}</Link2>
                                    <span className="block text-sm text-slate-400">{category}</span>
                                </div>
                                {/* Position the camera icon in the center */}
                                <div className="absolute z-10 opacity-0 group-hover:opacity-100 inset-0 flex items-center justify-center transition-all duration-500">
                                    <Link2 to="#" onClick={() => handleClick(category, 0)} className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16} /></Link2>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {isOpen && (
                <Lightbox
                    mainSrc={currentImages[photoIndex]}
                    nextSrc={currentImages[(photoIndex + 1) % currentImages.length]}
                    prevSrc={currentImages[(photoIndex + currentImages.length - 1) % currentImages.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + currentImages.length - 1) % currentImages.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % currentImages.length)}
                />
            )}
        </>
    );
}
