import React from "react";
import {expertiseData} from "../Data/data"
import { Link } from "react-router-dom";

export default function AboutUs(){
    
    return(
        <section className="relative md:py-24 py-16" id="about">
            <div className="container">
                <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                    <div className="lg:col-span-5 lg:px-8">
                        <div className="relative">
                            <div className="absolute inset-0 border dark:border-gray-800 rounded-full -mt-[10px] -ms-3 h-[100%] w-[100%] -z-1"></div>
                            <img src="/images/ab2.jpg" className="rounded-full shadow-md shadow-gray-200 dark:shadow-gray-800" alt=""/>

                            
                        </div>
                    </div>

                    <div className="lg:col-span-7">
                        <div className="lg:ms-5">
                        <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">Hello, I'm Alae Moumouh</h3>

                        <p className="text-slate-400 max-w-xl text-[15px]">At 21 years old, as a student of application development and AI, I'm driven by a strong passion for creating digital experiences. Technology and computers have fascinated me since childhood, sparking my journey into this vibrant field. Growing up immersed in gaming, I dreamed of crafting my own digital adventures.</p>

                        <p className="text-slate-400 max-w-xl text-[15px] mt-6">My commitment is focused on refining my skills in digital innovation, drawing from the enthusiasm and insights gained along my journey. Let me assist you in enhancing your online presence and expanding your business with customized digital solutions.</p>

                            <div className="mt-6">
                                <Link to="#project" className="btn bg-amber-500/10 hover:bg-amber-500 border-amber-500/10 hover:border-amber-500 text-amber-500 hover:text-white rounded-md me-2 mt-2">See Work</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">Hobbies & Expertise</h3>

                    <p className="text-slate-400 max-w-xl mx-auto text-[15px]">Aside from web design and development, I love playing football, lifting weights, and playing paddle. Explore my expertise below.</p>



                </div>

                <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                    {expertiseData.map((item,index)=>{
                        const Icon = item.Icon
                        return(
                            <div className="flex group shadow shadow-gray-200 dark:shadow-gray-800 dark:hover:shadow-gray-700 items-center p-3 rounded-lg bg-white dark:bg-slate-900" key={index}>
                            <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-amber-500/10 group-hover:bg-amber-500 text-amber-500 group-hover:text-white text-center rounded-xl me-5 transition-all duration-500">
                                <div className="rotate-45">
                                   <Icon className="h-5 w-5"/>
                                </div>
                            </div>
                            <div className="flex-1">
                                <h4 className="mb-0 text-[17px] font-medium">{item.title}</h4>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}