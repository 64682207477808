import * as Icon from 'react-feather';
export const expertiseData = [
    {
        title:'Websites',
        Icon: Icon.Monitor
    },
    {
        title:'Apps',
        Icon: Icon.Smartphone
    },
    {
        title:'Editing',
        Icon: Icon.Video
    },
    {
        title:'Coffee',
        Icon: Icon.Coffee
    },
    {
        title:'Game developing',
        Icon: Icon.Box
    },
    {
        title:'Designing',
        Icon: Icon.Figma
    },
    {
        title:'Trading',
        Icon: Icon.Activity
    },
    {
        title:'Webshops',
        Icon: Icon.ShoppingCart
    },
]
export const servicesData = [
    {
        title:'UX / UI Design',
        desc:'UX design focuses on enhancing user satisfaction by improving the usability and accessibility of a product, while UI design emphasizes the visual layout and interactive elements that users interact with.',
        Icon: Icon.Figma
        },
        {
        title:'Websites and Apps',
        desc:'Specializing in creating both websites and mobile applications, ensuring seamless user experiences across various platforms and devices.',
        Icon: Icon.Smartphone
        },
        {
        title:'Photoshop',
        desc:'Proficient in using Adobe Photoshop to create and manipulate digital images, ensuring visually stunning designs.',
        Icon: Icon.Camera
        },
        {
        title:'Fast Delivery',
        desc:'Committed to delivering projects promptly without compromising quality, ensuring timely completion and client satisfaction.',
        Icon: Icon.Clock
        },
        {
        title:'Web Security',
        desc:'Dedicated to implementing robust security measures to protect websites and applications from cyber threats and unauthorized access.',
        Icon: Icon.Key
        },
        {
        title:'SEO',
        desc:'Experienced in search engine optimization techniques to improve the visibility and ranking of websites on search engine results pages.',
        Icon: Icon.Search
        },

]
export const project = [
   {
    image: '/images/works/1.jpg',
    title:'Football App',
    subTitle: 'React',
   },
   {
    image: '/images/works/2.jpg',
    title:'Augmented Reality',
    subTitle: 'Mobile App Development',
   },
   {
    image: '/images/works/3.jpg',
    title:'Webscraper',
    subTitle: 'DevOps & Security',
   },
   {
    image: '/images/works/4.jpg',
    title:'Kvv Rauw',
    subTitle: 'Requirements Analysis',
   },
   {
    image: '/images/works/5.jpg',
    title:'Kvv Rauw',
    subTitle: 'Project PHP',
   },
   {
    image: '/images/works/6.jpg',
    title:'Mouse Trap',
    subTitle: 'IOT Essentials',
   },
   {
    image: '/images/works/7.jpg',
    title:'Machine Learning',
    subTitle: 'Artificial Intelligence',
   },
   {
    image: '/images/works/8.jpg',
    title:'Deep Learning',
    subTitle: 'Artificial Intelligence',
   },
   {
    image: '/images/works/9.jpg',
    title:'Qlik Sense',
    subTitle: 'Data Visualization',
   }
   
]
export const blogData = [
    {
        image:"/images/blog/01_ai_news.jpg",
        title:'Breaking AI News: Advancements and Breakthroughs',
        desc:'Stay updated with the latest developments in Artificial Intelligence. Explore groundbreaking research, innovations, and applications reshaping industries.'
    },
    {
        image:"/images/blog/01_app_dev.jpg",
        title:'Latest in App Development: Trends and Technologies',
        desc:'Discover the newest trends and technologies in app development. Learn about innovative approaches, tools, and frameworks revolutionizing the industry.'
    },
    {
        image:"/images/blog/01_tech_gadgets.jpg",
        title:'Tech and Gadgets Update: Must-Have Innovations',
        desc:'Explore the hottest tech and gadgets hitting the market. From cutting-edge devices to futuristic innovations, stay informed about the latest tech trends.'
    },
]

export const review = [
    {
        name:'A.M',
        designation:'Car Detailing',
        image:'/images/client/01.jpg',
        desc:"As a car detailer, I recently had the pleasure of having my website designed by this team. Their attention to detail and commitment to quality truly impressed me. Not only did they create a visually stunning website for my car detailing business, but their fast delivery and excellent communication made the whole process seamless."
    },
    {
        name:'Z.O',
        designation:'GoodieSmells',
        image:'/images/client/02.jpg',
        desc:"Running a webshop for perfume enthusiasts like myself demands a website that's as captivating as the scents we offer. This team not only met but exceeded my expectations. Their quick turnaround time and clear communication ensured that my GoodieSmells website was up and running in no time, delighting both me and my customers."
    },
    {
        name:'T.F.C',
        designation:'E-commerce',
        image:'/images/client/03.jpg',
        desc:"Transitioning my business to Shopify was a big step, but this team made the process incredibly smooth. Their expertise in e-commerce design and development ensured that my Shopify website was not only functional but also visually appealing. Their fast delivery and open communication made me confident in their abilities every step of the way."
    },
    {
        name:'J.P',
        designation:'Car Dealership',
        image:'/images/client/04.jpg',
        desc:"Managing a car dealership means having a website that showcases our inventory effectively. Thanks to this team, we now have a website that does just that. From listing all our cars to ensuring smooth navigation, they delivered beyond our expectations. Their prompt communication and efficient delivery made the whole experience stress-free."
    },
    {
        name:'B.G',
        designation:'Contractor',
        image:'/images/client/05.jpg',
        desc:"As a contractor, having a website that highlights our services is crucial. This team understood our needs perfectly and created a website that truly represents our brand. Their fast turnaround and clear communication made the entire process hassle-free. We couldn't be happier with the result."
    },
]

